import './CTAButton.css'

const CTAButton = (props) => {
    return (
        <button 
        className='cta-button'>
             <a href="https://github.com/"> Go to Github Repo </a>
        </button>
    );
  }
  
  export default CTAButton